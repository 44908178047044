/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2020 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import {
  Button as UIFoundationsButton,
  Text as UIFoundationsText,
  TextSectionHtmlTags,
  ButtonVariants,
  ButtonWidths,
  ButtonThemes,
  ButtonIconPositions
} from '@dcxwam/dcx-wam-ui-foundations'
import { pushDataLayer } from '../../../utils/datalayer'
import {
  getTextAlignmentMappedProp,
  getMarginBottomMappedProp
} from '../../../utils/props-mappings'
import ButtonEditConfig from './ButtonEditConfig'
import { EditableComponentPropsType } from '../../../utils/prop-types'
import { ButtonModelProps } from './types'

const Button: React.FC<EditableComponentPropsType<ButtonModelProps>> = ({
  ...props
}) => {
  const {
    text,
    textAlign,
    buttonVariant,
    accessibilityLabel,
    iconPosition,
    buttonWidth,
    buttonLink,
    icon,
    marginBottom,
    appliedCssClassNames,
    additionalClasses,
    id,
    gtmItems,
    ...rest
  } = props.model
  const editableProps = { ...props, model: { ...rest } }

  let variant = ButtonVariants.Contained
  switch (buttonVariant) {
    case 'contained':
      variant = ButtonVariants.Contained
      break
    case 'outlined':
      variant = ButtonVariants.Outlined
      break
    case 'text':
      variant = ButtonVariants.Text
      break
    default:
      break
  }

  let width = ButtonWidths.Auto
  switch (buttonWidth) {
    case 'auto':
      width = ButtonWidths.Auto
      break
    case '100%':
      width = ButtonWidths.Full
      break
    default:
      break
  }

  let theme = ButtonThemes.Light
  switch (appliedCssClassNames) {
    case 'cmp-theme--light':
      theme = ButtonThemes.Light
      break
    case 'cmp-theme--dark':
      theme = ButtonThemes.Dark
      break
    default:
      break
  }

  let buttonIconPosition = ButtonIconPositions.Left
  switch (iconPosition) {
    case 'DEFAULT':
      buttonIconPosition = ButtonIconPositions.Left
      break
    case 'END':
      buttonIconPosition = ButtonIconPositions.Right
      break
    default:
      break
  }

  const configAtom = {
    label: text,
    'aria-label': accessibilityLabel,
    buttonVariant: variant,
    icon,
    iconPosition: buttonIconPosition,
    buttonTheme: theme,
    buttonWidth: width,
    linkUrl: buttonLink?.url,
    linkTarget: buttonLink?.attributes?.target,
    className: additionalClasses,
    id,
    onClick: () => {
      pushDataLayer(gtmItems || [])
    }
  }

  const configSection = {
    component: TextSectionHtmlTags.P,
    hasMarginBottom: getMarginBottomMappedProp(marginBottom),
    textHorizontalAlignment: getTextAlignmentMappedProp(textAlign)
  }

  return (
    <EditableComponent {...editableProps}>
      {text ? (
        <UIFoundationsText.TextSection {...configSection}>
          <UIFoundationsButton {...configAtom} />
        </UIFoundationsText.TextSection>
      ) : (
        ButtonEditConfig.emptyLabel
      )}
    </EditableComponent>
  )
}

export default Button
